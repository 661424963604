import { signalStoreFeature, withMethods } from '@ngrx/signals';
import { ActionType } from '@aston/foundation';
import { inject } from '@angular/core';
import { type } from '@ngrx/signals';

import { PaymentScheduleDetailStore } from '../../accounting-module/stores/payment-schedule-detail/payment-schedule-detail.store';
import { NotificationCategory as N } from '../../shared-module/enums';
import { INotificationItem } from '../../shared-module/models';
import { ActionModalPanel } from '../../dunnings-module/enums';
import * as RoutesDefinitions from '../../routes-definitions';

export const withNotificationsRouter = () => signalStoreFeature(
	{
		methods: type<{ select: (id: number, opts: { route: string, extras: any }) => void }>(),
	},

	withMethods(store => {
		const psStore = inject(PaymentScheduleDetailStore)

		return {
			open: (notification: INotificationItem) => {

				let route = '';
				let extras;
	
				switch (notification.category) {
					case N.BlockedSeller:
						route = '';
						break;

					case N.NewKycItem:
						route = RoutesDefinitions.getKycControlRouteFullPath(notification.sellerId);
						break;

					// case N.SMSThresholdReached:
					// 	redirectionRoute = RoutesDefinitions.getAdminToolsOptionsRouteFullPath();
					// 	break;

					case N.SellerMention:
					case N.SubscriptionArbitration:
						route = RoutesDefinitions.getSellerDetailIdentityFullPath(notification.sellerId);
						break;

					case N.AccountingDocumentMention:
						route = RoutesDefinitions.getAccountingDetailFullPath(notification.accountingDocumentId);
						break;

					case N.PaymentScheduleMention:
						route = '';
						psStore.initOrOpenClosingDocumentOfSchedule(notification.paymentScheduleId)
						break;

					case N.SuperDebtorMention:
					case N.ScoringRiskStrong:
					case N.DsoCritical:
					case N.AverageOverdueCritical:
					case N.RecoveryAdvisorUpdated:
					case N.SuperDebtorCreated:
					case N.CreditLimitReached:
					case N.CreditLimitUpdated:
					case N.ProviderCreditLimitReached:
					case N.TemporaryCreditLimitEnded:
					case N.DebtorPortalPaymentLinkClicked:
					case N.NewAccountingDocumentCtx:
						route = RoutesDefinitions.getSuperDebtorDetailFullPath(notification.superDebtorId);
						break;

					case N.ScenariosEnded:
					case N.ScenarioRestarted:
						route = RoutesDefinitions.getSuperDebtorDetailFullPath(
							notification.superDebtorId,
							RoutesDefinitions.SuperDebtorDetailDunningRoutePath);
						extras = {
							queryParams: { anchor: RoutesDefinitions.SuperDebtorDetailDunningCaseAnchor }
						}
						break;

					case N.DunningActionMention:
					case N.DunningActionCreated:
					case N.DunningActionCompleted:
					case N.DunningActionPostalMailDeliveryProof:
					case N.PAAExpired:
					case N.DebtorPortalCommentAdded:
					case N.ScenarioStopped:
					case N.SMSReceived:
					case N.EmailResponses:
					case N.DunningActionPostalMailError:
					case N.DunningActionElectronicError:
					case N.DunningActionElectronicDeliveryProof:
						if (notification.category !== N.DebtorPortalCommentAdded || (notification.category === N.DebtorPortalCommentAdded && notification.dunningActionId)) {
							const act = actionTypeForCategory(notification.category);
							const panel = actionPanelForCategory(notification.category)
							const complete = act === ActionType.COMPLETE;
							const queryParams = RoutesDefinitions.getDunningActionParams(act, notification.dunningActionId, panel, complete)
							if (notification.category === N.PAAExpired) {
								queryParams.anchor = RoutesDefinitions.SuperDebtorDetailDunningAccountingAnchor;
							}
							route = RoutesDefinitions.getSuperDebtorDetailFullPath(
								notification.superDebtorId,
								RoutesDefinitions.SuperDebtorDetailDunningRoutePath);
							extras = queryParams;

						} else if (notification.category === N.DebtorPortalCommentAdded && notification.accountingDocumentId) {
							route = RoutesDefinitions.getAccountingDetailFullPath(
								notification.accountingDocumentId);
							break;

						}
						break;

					case N.EXRExpired:
						route = RoutesDefinitions.getSuperDebtorDetailFullPath(
							notification.superDebtorId,
							RoutesDefinitions.SuperDebtorDetailDunningRoutePath);
						extras = {
							queryParams: { anchor: RoutesDefinitions.SuperDebtorDetailDunningAccountingAnchor }
						}
						break;
	
					case N.AutomaticUnlettering:
						route = RoutesDefinitions.getSuperDebtorDetailFullPath(
							notification.superDebtorId,
							RoutesDefinitions.SuperDebtorDetailAccountingDocumentsRoutePath);
						break;

					case N.NewInsuranceDecisionReceived:
					case N.InsuranceDecision:
					case N.TransferArbitration:
					case N.LimitDateForDic:
						route = '';
						break;

					case N.ImportFailure:
					case N.ImportError:
						route = RoutesDefinitions.getImportListRouteFullPath();
						break;

					case N.TransferMention:
						route = RoutesDefinitions.getTransferDetailRouteFullPath(notification.transferId, notification.sellerId);
						break;
				}
	
				return store.select(notification.id, { route, extras })
			}
		}
	}),
)

function actionTypeForCategory(category: N) {
	return [
		N.DunningActionCompleted,
		N.DunningActionPostalMailDeliveryProof,
		N.SMSReceived,
		N.EmailResponses,
		N.DunningActionPostalMailError,
		N.DunningActionElectronicError,
		N.DunningActionElectronicDeliveryProof
	].includes(category)
		? ActionType.COMPLETE
		: ActionType.UPDATE;
}

function actionPanelForCategory(category: N) {
	return [
		N.DunningActionCompleted,
		N.DunningActionPostalMailDeliveryProof,
		N.SMSReceived,
		N.EmailResponses,
		N.DunningActionPostalMailError,
		N.DunningActionElectronicError,
		N.DunningActionElectronicDeliveryProof,
	].includes(category)
		? ActionModalPanel.Completion
		: [N.DebtorPortalCommentAdded].includes(category)
		? ActionModalPanel.Comments
		: ActionModalPanel.Form
}